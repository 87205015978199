import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Title from '../common/Title';
import Alert from '../common/Alert';

export default function Profile({ visible, saveUser, user, afterSave }) {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setEmail(user.email);
    setPhoneNumber(user.phone);
  }, [user]);

  const handleChange = (event) => {
    const { name, value } = event.target
    switch(name) {
      case 'email':
        setEmail(value);
        break;
      case 'phone':
        const phone = value.replace(/\D/g, '');
        setPhoneNumber(phone);
        break;
      default:
        break;
    }
  };

  const formatPhone = (phone) => {
    if (!phone) {
      return '';
    }
    const areaCode = phone.substring(0, 3);
    const middle = phone.substring(3, 6);
    const last = phone.substring(6, 10);
    return [areaCode, middle, last].filter(i => !!i).join('-');
  };

  const save = async () => {
    try {
      setSaving(true);
      await saveUser({ email, phoneNumber: `+1${phoneNumber}` });
      await afterSave();
      setSuccess(true);
      setErrorMessage(null);
    } catch (e) {
      console.error('Unable to save user', e);
      setSuccess(false);
      setErrorMessage(e.message);
    } finally {
      setSaving(false);
    }
  };

  if (!visible) {
    return null;
  }

  const onClose = () => setSuccess(null);

  return (
    <div>
      { success && <Alert type="success" message="Success" onClose={onClose} /> }
      { success === false && <Alert type="error" message={`Failed. ${errorMessage}`} onClose={onClose} /> }
      <Title stickyHeader aria-label="profile">
        Profile
      </Title>
      <form noValidate autoComplete="off">
        <div>
          <TextField
            id="email"
            label="Email"
            placeholder="example@gmail.com"
            value={email || ''}
            onChange={handleChange}
            name="email"
            margin="normal"
            fullWidth
          />
        </div>

        <div>
          <TextField
            id="phone"
            label="Phone Number"
            placeholder="555-555-5555"
            value={formatPhone(phoneNumber)}
            onChange={handleChange}
            name="phone"
            type="tel"
            inputProps={{ pattern: '[0-9]{3}-[0-9]{2}-[0-9]{3}' }}
            margin="normal"
            fullWidth
          />
        </div>

        <div>
          <Button variant="contained" color="primary" onClick={save} disabled={saving}>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
}

Profile.propTypes = {
  visible: PropTypes.bool,
  saveUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string
  }),
  afterSave: PropTypes.func
};

Profile.defaultProps = {
  visible: true,
  user: {},
  afterSave: () => {}
};
