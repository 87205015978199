import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles({
  table: {
    minWidth: 100,
  },
  hand: {
    cursor: 'pointer'
  }
});

export default function MyTable({headers, rows, onRowClick, renderRow, calculateRowClass, responsive}) {
  const classes = useStyles();

  const renderHeader = (header, i) => {
    const responsiveElements = responsive[i];
    if (!responsiveElements) {
      return <TableCell align="left" key={header}>{header}</TableCell>;
    }

    return (
      <Hidden only={responsiveElements} key={header}>
        <TableCell align="left">{header}</TableCell>
      </Hidden>
    );
  };

  const renderTheRow = (conversation, rowNumber) => {
    const row = renderRow(conversation).map((datum, i) => {
      const responsiveElements = responsive[i];
      const key = `${datum}-${i}`;
      if (!responsiveElements) {
        return <TableCell align="left" key={key}>{datum}</TableCell>;
      }
      return (
        <Hidden only={responsive[i]} key={key}>
          <TableCell align="left">{datum}</TableCell>
        </Hidden>
      );
    });

    return (
      <TableRow key={rowNumber} onClick={() => onRowClick(conversation)} classes={calculateRowClass(conversation)} hover>
        { row }
      </TableRow>
    );
  };

  return (
    <Box color="text.primary">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              { headers.map(renderHeader) }
            </TableRow>
          </TableHead>
          <TableBody>
            { rows.map(renderTheRow) }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

MyTable.propTypes = {
  headers: PropTypes.array,
  rows: PropTypes.array,
  onRowClick: PropTypes.func,
  renderRow: PropTypes.func,
  calculateRowClass: PropTypes.func,
  responsive: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]))
}

MyTable.defaultProps = {
  headers: [],
  rows: [],
  onRowClick: () => {},
  renderRow: () => {},
  calculateRowClass: () => {},
  responsive: []
}
