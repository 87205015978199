import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ChatFeed, Message } from '@jonnyman9/react-chat-ui';
import Box from '@material-ui/core/Box';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { v4 as uuidv4 } from 'uuid';
import ChatInput from './ChatInput';
import BotToggleButton from './BotToggleButton';
import ConversationActionButton from './ConversationActionButton';
import EditGuestModal from './EditGuestModal';
import Title from '../common/Title';
import Modal from '../common/Modal';
import Loading from '../common/Loading';
import { prettyDateTime, now } from '../../utils/time';
import { getMessages, saveMessage, saveConversation } from '../../services/api';

export default function Chat({ guestPhone, channel, botEnabled, guestName, guestNotes, onConversationUpdate, company, conversation }) {
  const [conversationId, setConversationId] = useState('');
  const [guestGuid, setGuestGuid] = useState('');
  const [rawMessages, setRawMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [message, setMessage] = useState('');
  const [savingMessage, setSavingMessage] = useState(false);
  const [savingConversation, setSavingConversation] = useState(false);
  const [editing, setEditing] = useState(false);
  const [newGuestName, setNewGuestName] = useState(guestName);
  const [newGuestNotes, setNewGuestNotes] = useState(guestNotes);
  const [savingGuest, setSavingGuest] = useState(false);
  const [viewingNotes, setViewingNotes] = useState(false);

  useEffect(() => {
    if (!conversation) {
      return;
    }
    setConversationId(conversation.conversation_id);
    setGuestGuid(conversation.guest_guid);
  }, [conversation]);

  useEffect(() => {
    async function markRead() {
      if (!conversationId) {
        return;
      }
      await saveConversation(conversationId, {checked_at: now()});
    }
    async function loadMessages() {
      if (!conversationId) {
        return;
      }
      const messages = (await getMessages(conversationId)) || [];
      messages.sort((a, b) => {
        if(a.created_at < b.created_at) { return -1; }
        if(a.created_at > b.created_at) { return 1; }
        return 0;
      });
      const chatMessages = messages.map(m => (
        new Message({
          id: (m.sender_guid === guestGuid) ? 1 : 0,
          message: m.body,
          createdAt: prettyDateTime(m.created_at)
        })
      ));
      setMessages(chatMessages);
      setRawMessages(messages);
      setLoaded(true);
    }

    loadMessages();
    markRead();
  }, [conversationId, guestGuid]);

  useEffect(() => {
    const channelName = 'new_message_feed';
    if (!channel) {
      return;
    }
    const onNewMessage = async (data) => {
      try {
        const messageExists = (message) => (
          rawMessages.find(m => (
            (m.body === message.body &&
              m.conversation_id === message.conversation_id &&
              m.created_at === message.created_at &&
              m.recipient_guid === message.recipient_guid &&
              m.sender_guid === message.sender_guid)
          ))
        );

        const newMessage = JSON.parse(data.body);
        if (newMessage.conversation_id === conversationId && !messageExists(newMessage)) {
          // brand new
          setRawMessages([...rawMessages, newMessage]);
          setMessages([...messages, new Message({
            id: (newMessage.sender_guid === guestGuid) ? 1 : 0,
            message: newMessage.body,
            createdAt: prettyDateTime(newMessage.created_at)
          })]);
          await saveConversation(conversationId, {checked_at: now()});
        }
      } catch (e) {
        console.error(e);
      }
    };
    channel.bind(channelName, onNewMessage);
    return () => {
      if (!channel || !channel.unbind) {
        return;
      }
      channel.unbind(channelName, onNewMessage);
    };
  }, [channel, messages, conversationId, guestGuid, rawMessages]);

  const onChangeInput = event => setMessage(event.target.value);

  const onKeyDownInput = async (event) => {
    if (event.key === 'Enter') {
      await sendMessage(event);
    }
  };

  const sendMessage = async (event) => {
    event.preventDefault();
    if (!conversationId) {
      return;
    }

    if (message.trim() === '') {
      return;
    }
    setSavingMessage(true);

    try {
      const newMessage = {body: message, message_request_id: uuidv4()};
      const createdMessage = await saveMessage(conversationId, newMessage);
      // FIXME: there is a potential race condition here.
      // Assuming response from creation BEATS the pusher message, since we just blindly
      // add the response (createdMessage).
      // If pusher beats us, then we'll have a duplicate when this gets added.
      setMessage('');
      setRawMessages([...rawMessages, createdMessage]);
      setMessages([...messages, new Message({
        id: 0,
        message,
        createdAt: prettyDateTime(createdMessage.created_at)
      })]);
    } catch (e) {
      // TODO: show user the error in a better way
      alert('Sorry! Unable to send message.');
      console.error(e);
    } finally {
      setSavingMessage(false);
    }
  };

  const toggleBotEnabled = async (event) => {
    event.preventDefault();
    if (!conversationId) {
      return;
    }

    try {
      setSavingConversation(true);
      const updatedConversation = await saveConversation(conversationId, {bot_enabled: !botEnabled});
      onConversationUpdate(updatedConversation);
    } catch (e) {
      console.error(e);
    } finally {
      setSavingConversation(false);
    }
  };

  const saveGuest = async (event) => {
    event.preventDefault();
    if (!conversationId) {
      return;
    }

    try {
      setSavingGuest(true);
      const updatedConversation = await saveConversation(conversationId, {bot_enabled: botEnabled, guest_name: newGuestName, guest_notes: newGuestNotes});
      setEditing(false);
      onConversationUpdate(updatedConversation);
    } catch(e) {
      console.log(e);
    } finally {
      setSavingGuest(false);
    }
  };

  const toggleEditing = async (event) => {
    event.preventDefault();
    setEditing(true);
  };

  const onChangeGuestNameInput = (event) => {
    event.preventDefault();
    setNewGuestName(event.target.value);
  };

  const onChangeGuestNotesInput = (event) => {
    event.preventDefault();
    setNewGuestNotes(event.target.value);
  };

  const toggleViewingNotes = (event) => {
    event.preventDefault();
    setViewingNotes(true);
  };

  const getName = () => [(guestName || ''), `(${guestPhone})`].join(' ')

  return (
    <Box color="text.primary">
      <EditGuestModal
        visible={editing}
        onClose={() => setEditing(false)}
        onNameChange={onChangeGuestNameInput}
        name={newGuestName}
        disabled={savingGuest}
        onNotesChange={onChangeGuestNotesInput}
        notes={newGuestNotes}
        onSave={saveGuest}
      />

      <Modal title={getName()} description={guestNotes} visible={viewingNotes} onClose={() => setViewingNotes(false)} />

      <Title style={{marginBottom: 0}}>
        {`Conversation with ${getName()}`}
      </Title>

      <ButtonGroup aria-label="recipient actions">
        <ConversationActionButton
          visible={!!guestNotes}
          onClick={toggleViewingNotes}
          icon="note"
        />
        <ConversationActionButton
          visible={!!loaded}
          disabled={savingConversation}
          onClick={toggleEditing}
          icon="pencil"
        />
      </ButtonGroup>

      {company &&
        <div className="clearfix">
          <BotToggleButton
            visible={!!loaded}
            disabled={savingConversation}
            onClick={toggleBotEnabled}
            botEnabled={botEnabled}
            company={company}
          />
        </div>
      }

      <Box boxShadow={0} borderColor="grey.400" border={1} borderRadius={5} paddingLeft={1}>
        <ChatFeed messages={messages} maxHeight={500} />
      </Box>

      <ChatInput
        visible={!!loaded}
        onChangeInput={onChangeInput}
        onKeyDownInput={onKeyDownInput}
        sendMessage={sendMessage}
        savingMessage={savingMessage}
        message={message}
      />

      <Loading visible={savingMessage} />
    </Box>
  );
}

Chat.propTypes = {
  guestPhone: PropTypes.string,
  channel: PropTypes.shape({bind: PropTypes.func}),
  botEnabled: PropTypes.bool,
  onConversationUpdate: PropTypes.func,
  company: PropTypes.shape({
    company_id: PropTypes.string,
    name: PropTypes.string,
    bot_enabled: PropTypes.bool
  }),
  conversation: PropTypes.shape({
    conversation_id: PropTypes.string,
    dest_guid: PropTypes.string,
    guest_guid: PropTypes.string
  })
};

Chat.defaultProps = {
  guestPhone: '',
  channel: {bind: () => {}},
  botEnabled: false,
  onConversationUpdate: () => {},
  company: null,
  conversation: null
};
