import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Loading({ visible, style }) {
  return !visible ? null : (
    <div style={style}>
      <CircularProgress />
    </div>
  );
}

Loading.propTypes = {
  visible: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

Loading.defaultProps = {
  visible: true,
  style: {
    margin: 'auto',
    textAlign: 'center'
  }
};
