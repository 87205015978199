import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { dayAgo } from "./utils/time";
import { Switch, Route, useHistory } from "react-router-dom";

import {
  getConversations,
  createPusher,
  getUserId,
  getUser,
  saveUser,
  getCompanies
} from "./services/api";
import { sortConversations } from "./utils/collections";
import Loading from "./components/common/Loading";
import Alert from "./components/common/Alert";
import ApplicationBar from "./components/app/ApplicationBar";
import ConversationListing from "./components/chat/ConversationListing";
import Conversation from "./components/chat/Conversation";
import Nav from "./components/app/Nav";
import Profile from "./components/profile/Profile";
import Company from "./components/company/Company";
import { routes } from "./utils/routes";
import "./App.css";

const drawerWidth = 200;
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}));

/*
 *  Load conversation from endpoint and sorts by read flag (unread at top)
 *  and by last_message_created_at, later dates at top.
 */
const loadConversations = async (
  setLoading,
  setConversations,
  options = { since: null, pageSize: null }
) => {
  const { since, pageSize } = options;
  setLoading(true);
  // FIXME: always just going to grab all until we can sort out the page size thing
  const convos = await getConversations(since, "all");
  setConversations(sortConversations(convos));
  setLoading(false);
};

export default function App() {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [channel, setChannel] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [user, setUser] = useState({});
  const [companies, setCompanies] = useState([]);
  const [alertMessages, setAlertMessages] = useState([]);
  const [since, setSince] = useState(dayAgo());
  const [conversationPaginationSize, setConversationPaginationSize] = useState(
    "all"
  );
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  useEffect(() => {
    let pusher;
    async function init() {
      await getConvo();
      await subscribe();
      await getTheUser();
      await getTheCompanies();
    }
    async function getConvo() {
      await loadConversations(setLoading, setConversations, {
        since,
        pageSize: conversationPaginationSize
      });
    }
    async function subscribe() {
      pusher = await createPusher();
      const userId = await getUserId();
      const channel = pusher.subscribe(`private-${userId}`);
      setChannel(channel);
    }
    function disconnect() {
      if (pusher) {
        pusher.disconnect();
      }
    }
    async function getTheUser() {
      const user = await getUser();
      setUser(user);
    }
    async function getTheCompanies() {
      const companies = await getCompanies();
      setCompanies(companies);
    }
    init();

    window.addEventListener("beforeunload", disconnect);
    return () => {
      window.removeEventListener("beforeunload", disconnect);
    };
  }, [
    setConversations,
    setLoading,
    setChannel,
    since,
    conversationPaginationSize
  ]);

  useEffect(() => {
    const channelName = "new_conversation_feed";
    const newMessageChannelName = "new_message_feed";
    if (!channel) {
      return;
    }

    channel.bind(channelName, data => {
      try {
        let found = false;
        const newConversation = JSON.parse(data.body);
        const newConversations = conversations.map(c => {
          if (c.conversation_id === newConversation.conversation_id) {
            found = true;
            return newConversation;
          }
          return c;
        });

        if (!found) {
          setConversations(
            sortConversations([...conversations, newConversation])
          );
        } else {
          setConversations(sortConversations(newConversations));
        }

        if (
          selectedConversation &&
          selectedConversation.conversation_id ===
            newConversation.conversation_id
        ) {
          setSelectedConversation(newConversation);
        }
      } catch (e) {
        console.error(e);
      }
    });

    const onNewMessage = data => {
      try {
        const message = JSON.parse(data.body);
        if (
          !selectedConversation ||
          selectedConversation.conversation_id !== message.conversation_id
        ) {
          const { body, conversation_id, created_at, recipient_guid } = message;
          let matchingConvo = null;
          const convos = conversations.map(c => {
            if (c.conversation_id === conversation_id) {
              c.read = false;
              c.last_message_created_at = created_at;
              matchingConvo = c;
            }
            return c;
          });
          setConversations(sortConversations(convos));

          /*  As per Errol on 2023-02-06, do not show alerts.
          const company = (companies || []).find(
            c => c.company_id === recipient_guid
          );
          if (company) {
            const fromMessage = matchingConvo
              ? `${matchingConvo.guest_name} (${matchingConvo.phone_guest})`
              : "Someone";
            const alertMessage = `${fromMessage} messaged ${company.name}: ${body}`;
            setAlertMessages([alertMessage, ...alertMessages]);
          }
          */
        }
      } catch (e) {
        console.error(e);
      }
    };
    channel.bind(newMessageChannelName, onNewMessage);
    return () => {
      if (!channel || !channel.unbind) {
        return;
      }
      channel.unbind(channelName);
      channel.unbind(newMessageChannelName, onNewMessage);
    };
  }, [channel, conversations, selectedConversation, companies, alertMessages]);

  const onBack = async () => {
    setSelectedConversation(null);
    await loadConversations(setLoading, setConversations, {
      since,
      pageSize: conversationPaginationSize
    });
    navigate(routes.home);
  };

  const onRefresh = async () => {
    await loadConversations(setLoading, setConversations, {
      since,
      pageSize: conversationPaginationSize
    });
  };

  const clearFilter = async () => {
    setSince(null);
    await loadConversations(setLoading, setConversations);
  };

  const applyFilter = async () => {
    const t = dayAgo();
    setSince(t);
    await loadConversations(setLoading, setConversations, {
      since: t,
      pageSize: conversationPaginationSize
    });
  };

  const navigate = newPath => history.push(newPath);

  const removeAlertMessage = message =>
    setAlertMessages(alertMessages.filter(m => m !== message));

  const afterSaveProfile = async () => {
    const user = await getUser();
    setUser(user);
  };

  const onRowClick = conversation => {
    setSelectedConversation(conversation);
    history.push(`${routes.conversations}/${conversation.conversation_id}`);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ApplicationBar
        className={classes.appBar}
        menuButtonClassName={classes.menuButton}
        handleDrawerToggle={handleDrawerToggle}
      />

      <Nav
        classes={classes}
        theme={theme}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        navigate={navigate}
        selectedConversation={selectedConversation}
      />

      <main className={classes.content}>
        <Toolbar />

        <Loading visible={loading} />

        {(alertMessages || []).map((m, i) => (
          <Alert key={i} message={m} onClose={() => removeAlertMessage(m)} />
        ))}

        <Switch>
          <Route exact path={routes.home}>
            <ConversationListing
              conversations={conversations}
              onRowClick={onRowClick}
              onRefresh={onRefresh}
              companies={companies}
              clearExternalFilter={clearFilter}
              externalFilterApplied={since != null}
              setExternalFilter={applyFilter}
              conversationPaginationSize={conversationPaginationSize}
              setConversationPaginationSize={setConversationPaginationSize}
            />
          </Route>

          <Route exact path={`${routes.conversations}/:conversationId`}>
            <Conversation
              onBack={onBack}
              channel={channel}
              selectedConversation={selectedConversation}
              conversations={conversations}
              setConversations={setConversations}
              companies={companies}
            />
          </Route>

          <Route exact path={routes.profile}>
            <Profile
              saveUser={saveUser}
              user={user}
              afterSave={() => afterSaveProfile()}
            />
          </Route>

          <Route exact path={routes.company}>
            <Company companies={companies} />
          </Route>
        </Switch>
      </main>
    </div>
  );
}
