import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

export default function Title({ children, style }) {
  return (
    <Typography component="h2" variant="h6" color="primary" style={style}>
      { children }
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

Title.defaultProps = {
  style: {
    marginTop: 10,
    marginBottom: 10
  }
};
