export const REGION = "us-east-1";
export const PUSHER_ID = "837dc205420daaa24de3";
export const PUSHER_REGION = "mt1";
export const API_BASE_URL =
  "https://f4z14pqnu5.execute-api.us-east-1.amazonaws.com/errolprod";
export const IDENTITY_POOL_ID =
  "us-east-1:7bb414eb-c798-4cae-8e2b-47335bc44685";
export const USER_POOL_ID = "us-east-1_qYrv4peYy";
export const USER_POOL_CLIENT_ID = "8miospiahdn5vbhl23898boem";

// export const API_BASE_URL = 'https://y4oyjjwxt9.execute-api.us-east-1.amazonaws.com/prod';
// export const IDENTITY_POOL_ID = 'us-east-1:d9950e0c-3da6-47be-b796-1b9b3fdc9432';
// export const USER_POOL_ID = 'us-east-1_ZZNmUHSMH';
// export const USER_POOL_CLIENT_ID = '7e2tds2uvjcm836s0fji4d1qlr';
