import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

export default function BotToggleButton({ visible, disabled, botEnabled, onClick, company }) {
  if (!visible || !company) {
    return null;
  }

  const style = {marginTop: '0.1rem', float: 'right'};
  if (!company.bot_enabled) {
    return (
      <div style={style}>
        {company.name} bot is disabled at the company level
      </div>
    );
  }

  return (
    <Button color="primary" disabled={disabled} onClick={onClick} style={style}>
      Turn {botEnabled ? 'off' : 'on'} bot for this conversation
    </Button>
  );
}

BotToggleButton.propTypes = {
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  botEnabled: PropTypes.bool,
  onClick: PropTypes.func,
  company: PropTypes.shape({
    company_id: PropTypes.string,
    name: PropTypes.string,
    bot_enabled: PropTypes.bool
  })
};

BotToggleButton.defaultProps = {
  visible: false,
  disabled: false,
  botEnabled: false,
  onClick: () => {},
  company: null
}
