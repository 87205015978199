import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Modal from '../common/Modal';

export default function EditGuestModal({ visible, onClose, onNameChange, name, disabled, onNotesChange, notes, onSave}) {
  return (
    <Modal title="Edit Guest" visible={visible} onClose={onClose}>
      <form noValidate autoComplete="off">
        <TextField
          label="Name"
          style={{ }}
          variant="outlined"
          placeholder="John Doe"
          margin="normal"
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          onChange={onNameChange}
          value={name}
          disabled={disabled}
        />
        <TextareaAutosize
          style={{width: '100%', resize: 'vertical', padding: 10}}
          aria-label="guest notes"
          rowsMin={3}
          margin="normal"
          placeholder="Guest Notes"
          onChange={onNotesChange}
          value={notes}
          disabled={disabled}
        />
        <Button variant="contained" disabled={disabled || name.trim() === ''} color="primary" onClick={onSave}>
          Save
        </Button>
      </form>
    </Modal>
  );
}

EditGuestModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onNameChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onNotesChange: PropTypes.func,
  notes: PropTypes.string,
  onSave: PropTypes.func
};

EditGuestModal.defaultProps = {
  visible: false,
  onClose: () => {},
  onNameChange: () => {},
  name: '',
  disabled: false,
  onNotesChange: () => {},
  notes: '',
  onSave: () => {}
};
