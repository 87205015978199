import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Title from '../common/Title';

export default function Company({ visible, companies }) {
  const [botEnabled, setBotEnabled] = useState(false);
  const [companyId, setCompanyId] = useState('');

  if (!visible) {
    return null;
  }

  const handleChange = (e) => {
    const companyId = (e.target.dataset || {}).id;
    setCompanyId(companyId);
    setBotEnabled(e.target.value);
  };

  const save = (e) => {
    e.preventDefault();
    console.log('save');
  };

  return (
    <div>
      <Title stickyHeader aria-label="profile">
        Company
      </Title>
      <form noValidate autoComplete="off">
        { companies.map(c => (
          <div key={c.company_id}>
            <h3 className="pb0 mb0">{c.name}</h3>
            <TextField
              inputProps={{ "data-id": c.company_id }}
              label="Bot Enabled"
              value={(companyId === c.company_id) ? botEnabled : c.bot_enabled}
              onChange={handleChange}
              name="bot_enabled"
              margin="normal"
              fullWidth
              disabled
            />
          </div>
        ))}
        <div className="pt1">
          <Button variant="contained" color="primary" onClick={save} disabled={true}>
            Save
          </Button>
        </div>
      </form>
    </div>
  );
}

Company.propTypes = {
  visible: PropTypes.bool,
  companies: PropTypes.arrayOf(PropTypes.shape({
    company_id: PropTypes.string,
    name: PropTypes.string,
    bot_enabled: PropTypes.boolean
  }))
};

Company.defaultProps = {
  visible: true,
  companies: []
};
