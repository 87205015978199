import React, { useState, useEffect, useMemo } from "react";
import ReactDOMServer from "react-dom/server";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import Hidden from "@material-ui/core/Hidden";
import PullToRefresh from "pulltorefreshjs";
import Title from "../common/Title";
import Table from "../common/Table";
import Select from "../common/Select";
import { prettyDateTime } from "../../utils/time";
import { prettyPhone } from "../../utils/phone";
import {
  setConversationPaginationSize,
  getConversationPaginationSize,
  setCompanyFilter as saveCompanyFilter,
  getCompanyFilter as retrieveCompanyFilter
} from "../../utils/preferences.js";
import { sortCompanies } from "../../utils/collections";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(0)
  }
}));

const getVisibleConversations = (conversations, companyFilter) => {
  let filtered = conversations;
  try {
    // company filter
    if (companyFilter) {
      filtered = filtered.filter(c => c.dest_guid === companyFilter);
    }

    return filtered;
  } catch (e) {
    console.error(e);
    return filtered;
  }
};

export default function ConversationListing({
  visible,
  conversations,
  onRowClick,
  onRefresh,
  companies,
  clearExternalFilter,
  externalFilterApplied,
  setExternalFilter,
  conversationPaginationSize,
  setConversationPaginationSize
}) {
  const [companyFilter, setCompanyFilter] = useState("");
  const classes = useStyles();

  useEffect(() => {
    const savedPaginationSize = getConversationPaginationSize();
    if (savedPaginationSize) {
      setConversationPaginationSize(savedPaginationSize);
    }

    const savedCompanyFilter = retrieveCompanyFilter();
    if (savedCompanyFilter) {
      setCompanyFilter(savedCompanyFilter);
    }
  }, [setConversationPaginationSize, setCompanyFilter]);

  useEffect(() => {
    PullToRefresh.init({
      mainElement: "#conversation-listing",
      onRefresh() {
        onRefresh();
      },
      iconRefreshing: ReactDOMServer.renderToString(<RefreshIcon />)
    });

    return () => PullToRefresh.destroyAll();
  }, [onRefresh]);

  const phoneDestToName = useMemo(
    () =>
      companies.reduce((acc, cur) => {
        acc[cur.phone] = cur.name;
        return acc;
      }, {}),
    [companies]
  );

  const containerStyle = {
    display: "flex",
    flex: 1
  };

  const onPaginationChange = e => {
    const page = e.target.value;
    setConversationPaginationSize(page);
  };

  const onCompanyFilterChange = e => {
    const newCompanyFilter = e.target.value;
    setCompanyFilter(newCompanyFilter);
    saveCompanyFilter(newCompanyFilter);
  };

  return !visible ? null : (
    <div id="conversation-listing">
      <Title stickyHeader aria-label="conversation list" style={containerStyle}>
        <div style={{ display: "flex", flex: 1, margin: "auto" }}>
          Conversations
        </div>
        <Hidden smDown>
          <div className="clearfix" style={containerStyle}>
            {externalFilterApplied && (
              <Button
                color="default"
                className={classes.button}
                onClick={clearExternalFilter}
              >
                Showing Most Recent
              </Button>
            )}
            {!externalFilterApplied && (
              <Button
                color="default"
                className={classes.button}
                onClick={setExternalFilter}
              >
                No Date Filter Applied
              </Button>
            )}
            <Select
              id="company-filter"
              label="Company"
              onChange={onCompanyFilterChange}
              options={[
                { key: "All", value: "" },
                ...sortCompanies(companies).map(c => ({
                  key: c.name,
                  value: c.company_id
                }))
              ]}
              value={companyFilter}
            />
            <Button
              color="default"
              className={classes.button}
              startIcon={<RefreshIcon />}
              onClick={onRefresh}
            >
              Refresh
            </Button>
          </div>
        </Hidden>
      </Title>
      <Table
        headers={[
          "Company",
          "Name",
          "Guest Phone",
          "Bot Enabled",
          "Last Guest Message Received At"
        ]}
        rows={getVisibleConversations(conversations, companyFilter)}
        onRowClick={onRowClick}
        calculateRowClass={c =>
          !c.read ? { root: "unread", hover: "hand" } : { hover: "hand" }
        }
        renderRow={c => [
          phoneDestToName[c.phone_dest],
          c.guest_name,
          prettyPhone(c.phone_guest),
          c.bot_enabled ? "Yes" : "No",
          c.last_message_created_at
            ? prettyDateTime(c.last_message_created_at)
            : ""
        ]}
        responsive={["", ["sm", "xs"], "", ["sm", "xs"], ["sm", "xs"]]}
      />
    </div>
  );
}

ConversationListing.propTypes = {
  visible: PropTypes.bool,
  conversations: PropTypes.arrayOf(
    PropTypes.shape({
      read: PropTypes.bool,
      phone_dest: PropTypes.string,
      guest_name: PropTypes.string,
      phone_guest: PropTypes.string,
      bot_enabled: PropTypes.bool,
      last_message_created_at: PropTypes.string
    })
  ),
  onRowClick: PropTypes.func,
  onRefresh: PropTypes.func,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      company_id: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string
    })
  ),
  clearExternalFilter: PropTypes.func,
  externalFilterApplied: PropTypes.bool,
  setExternalFilter: PropTypes.func,
  conversationPaginationSize: PropTypes.number,
  setConversationPaginationSize: PropTypes.func
};

ConversationListing.defaultProps = {
  visible: true,
  conversations: [],
  onRowClick: () => {},
  onRefresh: () => {},
  companies: [],
  clearExternalFilter: () => {},
  externalFilterApplied: true,
  setExternalFilter: () => {},
  conversationPaginationSize: null,
  setConversationPaginationSize: () => {}
};
