/**
 * Sort Conversations by read/unread flag and then secondarily by last_message_created_at
 */
export const sortConversations = (conversations) => (
  (conversations || []).sort((a, b) => {
    if (a.read && !b.read) {
      return 1;
    } else if (!a.read && b.read) {
      return -1;
    } else {
      const firstLastMessageCreatedAt = a.last_message_created_at || '';
      const secondLastMessageCreatedAt = b.last_message_created_at || '';
      if (firstLastMessageCreatedAt > secondLastMessageCreatedAt) {
        return -1;
      } else {
        return 1;
      }
    }
  })
);

/**
 * Sort companies by name.
 */
export const sortCompanies = (companies) => {
  if (!companies || !companies.length) {
    return companies;
  }

  companies.sort(function(a, b) {
    const nameA = (a.name || '').toUpperCase();
    const nameB = (b.name || '').toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  return companies;
};
