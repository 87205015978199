import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import PersonIcon from '@material-ui/icons/Person';
import InboxIcon from '@material-ui/icons/Inbox';
import BusinessIcon from '@material-ui/icons/Business';
import Hidden from '@material-ui/core/Hidden';
import { routes } from '../../utils/routes';

export default function Nav({ classes, theme, mobileOpen, handleDrawerToggle, navigate }) {
  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <List>
        <ListItem button key='Conversations' onClick={() => navigate(routes.home)}>
          <ListItemIcon><InboxIcon /></ListItemIcon>
          <ListItemText primary='Conversations' />
        </ListItem>
      </List>
      <List>
        <ListItem button key='Profile' onClick={() => navigate(routes.profile)}>
          <ListItemIcon><PersonIcon /></ListItemIcon>
          <ListItemText primary='Profile' />
        </ListItem>
      </List>
      <List>
        <ListItem button key='Company' onClick={() => navigate(routes.company)}>
          <ListItemIcon><BusinessIcon /></ListItemIcon>
          <ListItemText primary='Company' />
        </ListItem>
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden mdUp implementation="css">
        <Drawer
          container={window.document.body}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}

Nav.propTypes = {
  classes: PropTypes.shape({
    drawer: PropTypes.string,
    drawerPaper: PropTypes.string,
    drawerContainer: PropTypes.string
  }).isRequired,
  theme: PropTypes.shape({
    direction: PropTypes.string
  }).isRequired,
  mobileOpen: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  navigate: PropTypes.func.isRequired
};

Nav.defaultProps = {
  mobileOpen: false,
  handleDrawerToggle: () => {}
};
