import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MaterialAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Alert({ type, title, message, onClose }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <MaterialAlert severity={type} onClose={onClose}>
        { title && (
            <AlertTitle>{title}</AlertTitle>
          )
        }
        { message }
      </MaterialAlert>
    </div>
  );
}

Alert.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func
};

Alert.defaultProps = {
  type: 'info',
  title: '',
  onClose: () => {}
};
