const CONVO_PAGINATION_SIZE = 'CONVO_PAGINATION_SIZE';
const COMPANY_FILTER = 'COMPANY_FILTER';

const setCompanyFilter = (filter) => {
  if (!filter) {
    localStorage.removeItem(COMPANY_FILTER);
    return null;
  }
  localStorage.setItem(COMPANY_FILTER, filter);
  return filter;
};
const getCompanyFilter = () => localStorage.getItem(COMPANY_FILTER);

const setConversationPaginationSize = (size) => {
  if (!size) {
    localStorage.removeItem(CONVO_PAGINATION_SIZE);
    return null;
  }
  const value = size.toString();
  localStorage.setItem(CONVO_PAGINATION_SIZE, value);
  return value;
};
const getConversationPaginationSize = () => localStorage.getItem(CONVO_PAGINATION_SIZE);

export {
  setConversationPaginationSize,
  getConversationPaginationSize,
  setCompanyFilter,
  getCompanyFilter
};
