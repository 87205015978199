import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../../logo.png';

const useStyles = makeStyles((_theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ApplicationBar({ className, menuButtonClassName, handleDrawerToggle }) {
  const thisClasses = useStyles();
  return (
    <AppBar position="fixed" className={className}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={menuButtonClassName}
        >
          <MenuIcon />
        </IconButton>

        <Typography variant="h6" className={thisClasses.title}>
          <img src={logo} alt="logo" width="65" height="60" style={{verticalAlign: 'middle'}} />
        </Typography>
        <AmplifySignOut />
      </Toolbar>
    </AppBar>
  );
}

ApplicationBar.propTypes = {
  className: PropTypes.string,
  menuButtonClassName: PropTypes.string,
  handleDrawerToggle: PropTypes.func
};

ApplicationBar.defaultProps = {
  className: '',
  menuButtonClassName: '',
  handleDrawerToggle: () => {}
}
