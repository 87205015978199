import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';

export default function ChatInput({ visible, onChangeInput, message, onKeyDownInput, savingMessage, sendMessage }) {
  const [inputFocused, setInputFocused] = useState(false);
  if (!visible) {
    return null;
  }

  const onFocus = () => setInputFocused(true);
  const onBlur = () => setInputFocused(false);
  const formStyle = {display: 'flex', flex: 1, justifyContent: 'space-between'};
  const sendButtonStyle = {display: 'flex', flex: 0.05, height: '4.8rem', marginTop: '8px', marginLeft: '0.2rem'};
  const inputStyle={
    display: 'flex',
    marginTop: 8,
    flex: 0.95,
    fontSize: '0.875rem',
    outline: 'none',
    resize: 'vertical',
    minHeight: '1.5rem'
  };
  if (inputFocused) {
    inputStyle.borderColor = '#90caf9';
    inputStyle.borderWidth = '2px';
  }
  return (
    <form noValidate autoComplete="off" style={formStyle}>
      <TextareaAutosize
        label="Message"
        style={inputStyle}
        placeholder="Type your message here - Enter sends"
        onChange={onChangeInput}
        value={message}
        onKeyDown={onKeyDownInput}
        disabled={savingMessage}
        rowsMin={4}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <Button variant="contained" disabled={savingMessage || message.trim() === ''} color="primary" onClick={sendMessage} style={sendButtonStyle}>
        Send
      </Button>
    </form>
  );
}

ChatInput.propTypes = {
  visible: PropTypes.bool,
  onChangeInput: PropTypes.func,
  onKeyDownInput: PropTypes.func,
  sendMessage: PropTypes.func,
  savingMessage: PropTypes.bool,
  message: PropTypes.string
};

ChatInput.defaultProps = {
  visible: false,
  onChangeInput: () => {},
  onKeyDownInput: () => {},
  sendMessage: () => {},
  savingMessage: false,
  message: ''
};
