import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import App from './App';

function Main() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default withAuthenticator(Main);
