import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useParams } from 'react-router-dom';
import Chat from './Chat';
import { sortConversations } from '../../utils/collections';

export default function Conversation({ visible, onBack, channel, selectedConversation, conversations, setConversations, companies }) {
  const { conversationId } = useParams();
  if (!visible) {
    return null;
  }

  if (!conversationId || !conversations || !conversations.length) {
    // parent component hasn't loaded conversations yet or url is messed up
    return null;
  }

  let currentConversation = selectedConversation;
  if (!currentConversation) {
    // this happens if someone just types in the URL
    // and we didn't get a chance to capture the object before
    // we rendered this component, but its ok we can fetch it
    currentConversation = conversations.find(c => c.conversation_id === conversationId);
  }

  if (!currentConversation) {
    return null;
  }

  const {
    dest_guid: destGuid,
    phone_guest: phoneGuest,
    bot_enabled: botEnabled,
    guest_name: guestName,
    guest_notes: guestNotes
  } = currentConversation;
  const company = (companies || []).find(c => c.company_id === destGuid);
  return (
    <div>
      <div className="clearfix">
        <Button onClick={onBack} style={{paddingLeft: 0}}>
          <ArrowBackIcon /> Back
        </Button>
      </div>
      <Chat
        channel={channel}
        conversation={currentConversation}
        guestPhone={phoneGuest}
        botEnabled={botEnabled}
        guestName={guestName}
        guestNotes={guestNotes}
        onConversationUpdate={(conversation) => {
          const newConversations = conversations.map(c => (c.conversation_id === conversation.conversation_id) ? conversation : c);
          setConversations(sortConversations(newConversations));
        }}
        company={company}
      />
    </div>
  );
}

Conversation.propTypes = {
  visible: PropTypes.bool,
  onBack: PropTypes.func,
  channel: PropTypes.shape({bind: PropTypes.func}),
  selectedConversation: PropTypes.shape({
    conversation_id: PropTypes.string,
    phone_guest: PropTypes.string,
    bot_enabled: PropTypes.bool,
    guest_name: PropTypes.string,
    guest_notes: PropTypes.string
  }),
  conversations: PropTypes.arrayOf(PropTypes.shape({
    conversation_id: PropTypes.string
  })),
  setConversations: PropTypes.func,
  companies: PropTypes.arrayOf(PropTypes.shape({
    company_id: PropTypes.string,
    name: PropTypes.string,
    bot_enabled: PropTypes.bool
  }))
};

Conversation.defaultProps = {
  visible: true,
  onBack: () => {},
  channel: {bind: () => {}},
  selectedConversation: null,
  conversations: [],
  setConversations: () => {},
  companies: []
};
