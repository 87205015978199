import { DateTime } from "luxon";

function convertDatetimeToIso(dt = null) {
  return (dt || DateTime.local())
    .toString()
    .replace(/^(.*\d{2}):(\d{2})$/, "$1$2");
}

export function prettyDateTime(iso) {
  return DateTime.fromISO(iso).toLocaleString(DateTime.DATETIME_MED);
}

export function now() {
  return convertDatetimeToIso();
}

export function oneHourAgo() {
  // return convertDatetimeToIso(DateTime.local().minus({ hours: 2 }));
  return convertDatetimeToIso(DateTime.local().minus({ hours: 1 }));
}

export function dayAgo() {
  return convertDatetimeToIso(DateTime.local().minus({ days: 1 }));
}

export function weekAgo() {
  return convertDatetimeToIso(DateTime.local().minus({ days: 7 }));
}

export function monthAgo() {
  return convertDatetimeToIso(DateTime.local().minus({ days: 31 }));
}
