import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import NoteIcon from '@material-ui/icons/Note';
import EditIcon from '@material-ui/icons/Edit';

export default function ConversationActionButton({ visible, onClick, disabled, icon }) {
  if (!visible) {
    return null;
  }

  let iconWidget = null;
  switch(icon) {
    case 'note':
    case 'notes':
    case 'view':
      iconWidget = <NoteIcon fontSize="small" />;
      break;
    case 'pencil':
    case 'edit':
      iconWidget = <EditIcon fontSize="small" />;
      break;
    default: 
      iconWidget = null;
  }

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      style={{margin: 0, padding: 0}}
      size="small"
      variant="outlined"
      color="primary"
    >
      { iconWidget }
    </Button>
  );
}

ConversationActionButton.propTypes = {
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.string
};

ConversationActionButton.defaultProps = {
  visible: false,
  disabled: false,
  onClick: () => {},
  icon: ''
}
