import { path } from "ramda";
import { API_BASE_URL, PUSHER_ID, PUSHER_REGION } from "../utils/constants";
import Auth from "@aws-amplify/auth";
import Pusher from "pusher-js";

const getIdToken = async () => {
  const session = await Auth.currentSession();
  if (!session) {
    return null;
  }
  const idToken = session.getIdToken();
  if (!idToken) {
    return null;
  }

  return idToken.getJwtToken();
};

const getOpts = async () => {
  const idToken = await getIdToken();
  return {
    method: "GET",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`
    },
    referrerPolicy: "no-referrer"
  };
};

const createPostOpts = async data => {
  const idToken = await getIdToken();
  return {
    method: "POST",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data)
  };
};

const createPutOpts = async data => {
  const idToken = await getIdToken();
  return {
    method: "PUT",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data)
  };
};

// Unused, should happen automatically with Amplify
// https://github.com/aws-amplify/amplify-js/issues/446
/*
async function refreshToken() {
  const session = await Auth.currentSession();
  const user = await Auth.currentAuthenticatedUser();
  return new Promise((resolve, reject) => {
    user.refreshSession(session.getRefreshToken(), (err, newSession) => {
      if (err) {
        return reject(err);
      }
      return resolve(newSession);
    });
  });
}
*/

/* // Currently unused, filtering is happening server side.
export const getUserGroups = async () => {
  const user = await Auth.currentAuthenticatedUser();
  if (!user) {
    return null;
  }

  const session = user.signInUserSession;
  if (!session) {
    return null;
  }

  const accessToken = session.accessToken;
  if (!accessToken) {
    return null;
  }

  return accessToken.payload['cognito:groups'];
};
*/

export const getUserId = async () => {
  const user = await Auth.currentAuthenticatedUser();
  return user ? user.username : null;
};

export const getUser = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const attributes = user.attributes;
  const phone = attributes.phone_number;
  return {
    email: attributes.email,
    phone: phone.substring(2),
    id: attributes.sub
  };
};

export const getCompanies = async () => {
  try {
    const opts = await getOpts();
    const response = await fetch(`${API_BASE_URL}/companies`, opts);
    const json = await response.json();
    return path(["companies"], json) || [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getConversations = async (since = null, paginationSize = null) => {
  try {
    const opts = await getOpts();
    const url = new URL(`${API_BASE_URL}/conversations`);
    if (since) {
      url.searchParams.append("since", since);
    }
    if (paginationSize) {
      url.searchParams.append("pageSize", paginationSize);
    }

    const response = await fetch(url.href, opts);
    const json = await response.json();
    return path(["conversations"], json) || [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const getMessages = async conversationId => {
  try {
    const opts = await getOpts();
    const response = await fetch(
      `${API_BASE_URL}/messages/${conversationId}`,
      opts
    );
    const json = await response.json();
    return path(["messages"], json) || [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const saveCompany = async botEnabled => {};

// will throw an exception if cannot save
export const saveMessage = async (conversationId, message) => {
  const opts = await createPostOpts(message);
  const response = await fetch(
    `${API_BASE_URL}/messages/${conversationId}`,
    opts
  );
  const json = await response.json();
  return path(["conversation_message"], json);
};

export const saveConversation = async (conversationId, conversation) => {
  const opts = await createPutOpts(conversation);
  const response = await fetch(
    `${API_BASE_URL}/conversations/${conversationId}`,
    opts
  );
  const json = await response.json();
  return path(["conversation"], json);
};

export const createPusher = async () => {
  const idToken = await getIdToken();
  return new Pusher(PUSHER_ID, {
    authEndpoint: `${API_BASE_URL}/pusher/auth`,
    cluster: PUSHER_REGION,
    auth: {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`
      }
    }
  });
};

export const saveUser = async ({ email, phoneNumber }) => {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(user, { email, phone_number: phoneNumber });
  user.email = email;
  user.phone_number = phoneNumber;
  user.phoneNumber = phoneNumber;
  return user;
};
