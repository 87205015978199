import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import MaterialSelect from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Select({ id, label, onChange, helperText, options, value }) {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <MaterialSelect
        labelId={`${id}-label`}
        id={id}
        value={value}
        onChange={onChange}
      >
        { (options || []).map(o => <MenuItem key={o.key} value={o.value}>{o.key}</MenuItem>) }
      </MaterialSelect>
      { helperText && <FormHelperText>{helperText}</FormHelperText> }
    </FormControl>
  );
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  })),
  value: PropTypes.string
};

Select.defaultProps = {
  helperText: null,
  options: [],
  value: null
};
